import { useContext, useEffect } from "react";
import Context from "../../components/Helpers/Context";
import { Breadcrumbs, initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import { InputField, ReactSelect, SubmitButton } from "../../components/FormHelper";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { USER_UPDATE } from "../../components/ApiRoutes";

const UserProfile = () => {

    const [context, setContext] = useContext(Context)
    const [gender, setGender] = useState();
    const [date, setDate] = useState(null); 
    const [tinyloader, setTinyloader] = useState(false); 
    const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

    const updateUser = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('user'));
            formdata.append('id', context?.auth?.id)
            postData(USER_UPDATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res && res.status) {
                    setContext(prevState => ({ ...prevState, auth: res?.data }))
                    initialFormState('user', true);

                }
            })
        }
    }

    useEffect(() => {
        setDate(context && context.auth && context.auth.dob ? new Date(context.auth.dob) : null)

        if (context && context.auth) {
            let gender = [{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }].filter((item) => item.value === context.auth.gender)
            setGender(gender[0] ?? null)
        }
    }, [context])
    return (
        <>
            <Breadcrumbs pageName="User Profile" parent="Setting" />
            <div className="row">
                <div className="card main-profile-page">
                    <div className="main-profile p-2 border border-1">
                        <div className="row align-items-center mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <div className="profile-main">
                                    <img src={context?.auth?.role_id !== 1 && context?.auth?.profile_image ? ASSET_ENDPOINT+context?.auth?.profile_image : './assets/images/user.png'} className="rounded-circle" alt="User Profile" />
                                </div>
                                <div className="profile-details ml-2">
                                    <h4>{context?.auth?.first_name} {context?.auth?.middle_name} {context?.auth?.last_name}</h4>
                                    <h6>{context?.auth?.role}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <form className="needs-validation form-update-user" id="user" noValidate>
                                    <div className="details mb-5">
                                        <h5 className="pb-4">Personal Info</h5>
                                        <div className="row mt-2">
                                            <InputField col={3} name="first_name" id="first_name" placeholder="First Name" defaultValue={context?.auth?.first_name} required />
                                            <InputField col={3} name="middle_name" id="middle_name" placeholder="Middle Name" defaultValue={context?.auth?.middle_name} />
                                            <InputField col={3} name="last_name" id="last_name" placeholder="Last Name" defaultValue={context?.auth?.last_name} required />
                                        </div>
                                        <div className="row mt-2">
                                            <div className="mt-1 mx-0  col-12 col-md-6 col-lg-4">
                                                <label htmlFor="date" className="col-form-label pb-1">Date of Birth <sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                                <div className="">
                                                    <DatePicker wrapperClassName="w-100" className="form-control" placeholderText="YYYY-MM-DD" name="dob" id="dob" dateFormat="yyyy-MM-dd" selected={date} onChange={(d) => setDate(d)} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" required />
                                                </div>
                                            </div>
                                            <ReactSelect label="Gender" name="gender" id="gender" value={gender} size="col-12 col-md-6 col-lg-4 mt-2" required onChange={(e) => setGender(e)} options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }]} />
                                            <InputField col={3} name="phone" id="phone" placeholder="Phone" defaultValue={context?.auth?.phone} required />
                                        </div>   
                                        <div className="row mt-2">
                                            <InputField col={3} type="email" name="email" id="email" placeholder="Enter Your Email" defaultValue={context?.auth?.email} required />
                                            <InputField col={3} type="role" name="role" id="role" defaultValue={context?.auth?.role} required readOnly />
                                            {context?.auth?.role_id!==1 && 
                                                <InputField col={3} type="file" name="profile_image" id="profile_image" label="Profile Image" />
                                            }
                                        </div>
                                        <div className="col-sm-12 d-flex justify-content-end mt-5 ">
                                            <SubmitButton load={tinyloader} title={"Update"} type="submit" className="btn btn-primary" name={'Update'} action={updateUser} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserProfile