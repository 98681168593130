import { Link } from "react-router-dom";
import { LOGIN } from "../../components/ApiRoutes";
import { InputField, SubmitButton } from "../../components/FormHelper";
import { postData, validateForm } from "../../components/Helpers/Helper";
import { useContext, useState } from "react";
import Context from "../../components/Helpers/Context";


const Login = () => {
    
    // eslint-disable-next-line
    const [context, setContext] = useContext(Context);
    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
       
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('login-form'));
            postData(LOGIN, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    localStorage.setItem("hospital-token", res.data.access_token);
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data,
                    }))
                    window.location.href = '/dashboard'
                }

            })
        }
    }
    return (
        <>
            <div id="wrapper" className="theme-cyan">
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main hospital">
                        <div className="auth-box">
                            <div className="top">
                                <img src="./logo.jpg" alt="Iconic" />
                                <span className="text-white h5 ml-2">Medical Dashboard</span>
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Login to your account</p>
                                </div>
                                <div className="body">
                                    <form className="form-auth-small needs-validation" id="login-form" noValidate>
                                        <InputField type="text" id="username" name="username" required />
                                        <InputField type="password" id="password" name="password" required />
                                        <div className="bottom text-right">
                                            <span className="helper-text">
                                                <Link to="/forgot-password">Forgot password?</Link>
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <SubmitButton load={tinyloader} type="submit" title="Log In" action={submitForm} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login