import { useEffect, useState } from "react";
import { ReactSelect, SubmitButton } from "../../../components/FormHelper";
import { postData, validateForm } from "../../../components/Helpers/Helper";
import { ADD_ROOM, UPDATE_ROOM } from "../../../components/ApiRoutes";
import { now } from "lodash";

const AddUpdateRoom = (props) => {

    const [roomType, setRoomType] = useState('')
    const [loader,setLoader] = useState(false)

    const [selectedRoomType, setSelectedRoomType] = useState('')

    const addUpdateRoom = (e) => {
        e.preventDefault();

        if(validateForm(e, 'addUpdateRoom')){
            setLoader(true)
            let formData = new FormData(document.getElementById('addUpdateRoom'));
            let apiUrl = props?.details ? UPDATE_ROOM : ADD_ROOM;
            postData(apiUrl, 'POST', formData, true, true, (res) => {
                setLoader(false)
                if(res && res.success){
                    setSelectedRoomType('')
                    document.querySelector('#roomModal [data-dismiss="modal"]').click()
                    props.loading(now)
                }

            })
        }
    }

    useEffect(() => {
        let room_type = [
            {
                value: 'Delux',
                label: 'Delux',
            },
            {
                value: 'Super Delux',
                label: 'Super Delux',
            },
            {
                value: 'ICU',
                label: 'ICU',
            },
        ];

        setRoomType(room_type)

        if(props && props.details){
            let room_type_data = {
                value: props?.details?.room_type,
                label: props?.details?.room_type, 
            }

            setSelectedRoomType(room_type_data)
        }else{
            setSelectedRoomType('')
        }
        // eslint-disable-next-line
    },[props && props.details])

    return (
        <>
            <div className="modal fade" id="roomModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{ props?.details ? 'Update Room' : 'Add Room' }</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                           <form className="needs-validation" noValidate id="addUpdateRoom">
                                <input type="hidden" name="id" value={props?.details?.id} />
                                <div className="col-md-12">
                                    <ReactSelect size="col-md-12" value={selectedRoomType} options={roomType} label="Room Type" name="room_type" id="room-type" onChange={(e) => setSelectedRoomType(e)} required />
                                </div>
                           </form>
                        </div>
                        <div className="modal-footer mx-4">
                            <SubmitButton load={loader} action={(e) => addUpdateRoom(e)} title={'Submit'} />
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AddUpdateRoom;