import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './components/Routing';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <div className="position-fixed bottom-0 end-0 p-2 toast-end pointer-none" style={{ 'zIndex': '1111' }} id="toastContainer"></div>
    <Routing />
  </Router>
);

reportWebVitals();
