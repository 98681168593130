
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { InputField, SubmitButton } from "../../components/FormHelper";
import { initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import { useState } from "react";
import { RESET_PASSWORD } from "../../components/ApiRoutes";

const ResetPassword = () => {
    const [param] = useSearchParams();
    const navigate = useNavigate()
    const token = param.get('token');
    const [tinyloader, setTinyloader] = useState(false);
    const submitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('reset-password-form'));
            formdata.append('token', token);
            postData(RESET_PASSWORD, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('reset-password-form');
                    navigate('/')
                }
            })
        }
    }
    return (
        <>
            <div id="wrapper" className="theme-cyan">
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main hospital">
                        <div className="auth-box">
                            <div className="top">
                            <img src="./logo.jpg" alt="Iconic" />
                                <span className="text-white h5 ml-2">Medical Dashboard</span>
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Reset Password</p>
                                </div>
                                <div className="body">
                                    <form className="form-auth-small needs-validation" id="reset-password-form" noValidate>
                                        <InputField id="password" type="password" name="password" required />
                                        <InputField id="confirm_password" type="password" name="confirm_password" required />
                                        <div className="d-flex justify-content-end">
                                            <SubmitButton load={tinyloader} type="submit" title="Submit" action={submitForm} />
                                        </div>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <Link to="/">Login</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ResetPassword