import { Breadcrumbs, getData, initialFormState, postData, validateForm } from "../../components/Helpers/Helper"
import { InputField, ReactSelect, SubmitButton } from "../../components/FormHelper"
import { useEffect, useState } from "react"
import { ADD_LAB, MASTER_DROPDOWN, UPDATE_LAB } from "../../components/ApiRoutes"
import { useLocation, useNavigate } from "react-router-dom"
import _ from "lodash";
const LabsImaging = () => {

    const location = useLocation().state
    const navigate = useNavigate()

    const [tinyLoader, setTinyLoader] = useState(false)

    const [selectedDropdown, setSelectedDropDown] = useState({
        patient_name: null,
        doctor_name: null,
        lab_type: null,
        work_status: null
    })

    const [dropDownData, setDropDownData] = useState({
        patient_name: [],
        doctor_name: [],
        lab_type: [
            { value: 'ct', label: 'CT' },
            { value: 'pathalogy', label: 'Pathalogy' },
            { value: 'x-ray', label: 'X-Ray' },
        ],
        work_status: [
            { value: 'registered', label: 'Registered' },
            { value: 'in_progress', label: 'In Progress' },
            { value: 'report_generated', label: 'Report Generated' }
        ],
    })

    const saveData = (e) => {
        e.preventDefault();

        if (validateForm(e, 'labImaging')) {
            setTinyLoader(true)
            let formData = new FormData(document.getElementById('labImaging'));
            let apiURL = location && location.id ? UPDATE_LAB : ADD_LAB;
            postData(apiURL, 'POST', formData, true, true, (res) => {
                setTinyLoader(false)
                if (res.status) {
                    initialFormState('labImaging', true)
                    navigate('/labs-imaging')
                }
            })
        }
    }

    useEffect(() => {

        getData(`${MASTER_DROPDOWN}?type=doctor`, true, (res) => {
            if (res.status) {
                setDropDownData(prevState => ({ ...prevState, doctor_name: res.data }))
                if (location) {
                    setSelectedDropDown(prevState => ({ ...prevState, doctor_name: { value: location?.doctor_data?.value, label: location?.doctor_data?.label } }))
                }
            }
        })

        getData(`${MASTER_DROPDOWN}?type=patient`, true, (res) => {
            if (res.status) {
                setDropDownData(prevState => ({ ...prevState, patient_name: res.data }))
                if (location) {
                    setSelectedDropDown(prevState => ({ ...prevState, patient_name: { value: location?.patient_data?.value, label: location?.patient_data?.label } }))
                }
            }
        })

        if (location) {
            setSelectedDropDown(prevState => ({ ...prevState, work_status: { value: location?.work_status, label: _.startCase(_.replace(location?.work_status, '_', ' ')) } }))
            setSelectedDropDown(prevState => ({ ...prevState, lab_type: { value: location?.lab_type, label: _.startCase(_.replace(location?.lab_type, '_', ' ')) } }))
        }


    }, [location])
    
    return (
        <>
            <Breadcrumbs pageName="Labs & Imaging" />
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="header">
                            <h2>Labs & Imaging Information {location && `>> ${location?.lab_id}`}</h2>
                        </div>
                        <div className="body">
                            <form className="need-validation" noValidate id="labImaging">
                                <input type="hidden" name="id" value={location?.id} />
                                <div className="row clearfix">
                                    <ReactSelect name="patient_name" id="patient_name" value={selectedDropdown.patient_name} label="Patient Name" options={dropDownData.patient_name} size="col-lg-3 col-md-3 col-sm-6 mt-2" onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, patient_name: e }))} required />
                                    <ReactSelect name="doctor_name" id="doctor_name" value={selectedDropdown.doctor_name} label="Doctor Name" options={dropDownData.doctor_name} size="col-lg-3 col-md-3 col-sm-6 mt-2" onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, doctor_name: e }))} required />
                                    <ReactSelect name="lab_type" id="lab_type" value={selectedDropdown.lab_type} label="Lab Type" options={dropDownData.lab_type} size="col-lg-3 col-md-3 col-sm-6 mt-2" required onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, lab_type: e }))} />
                                    <ReactSelect name="work_status" id="work_status" value={selectedDropdown.work_status} label="Work Status" options={dropDownData.work_status} size="col-lg-3 col-md-3 col-sm-6 mt-2" onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, work_status: e }))} required />
                                    <InputField type="file" className="form-control" id="lab_document" name="lab_document" col={2} />
                                    <InputField type="file" className="form-control" id="lab_report" name="lab_report" col={2} />
                                </div>
                                <div className="row clearfix">
                                    <div className="col-sm-12">
                                        <div className="form-group m-t-20">
                                            <label htmlFor="description">Description</label>
                                            <textarea rows="6" className="form-control" id="description" name="description" placeholder="Description" defaultValue={location?.description}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <SubmitButton load={tinyLoader} title="Submit" action={(e) => saveData(e)} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default LabsImaging;



