import { InputField, SubmitButton } from "../../components/FormHelper";
import { Breadcrumbs, initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import { useState } from "react";
import { CHANGE_PASSWORD } from "../../components/ApiRoutes";

const ChangePassword = () => {

    const [tinyloader, setTinyloader] = useState(false);

    const submitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('change-password'));
            postData(CHANGE_PASSWORD, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('change-password');
                }
            })
        }
    }

    return (
        <>
            <Breadcrumbs pageName="Change Password" parent="Setting" />
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="header">
                            <h2>Change Password</h2>
                        </div>
                        <div className="body">
                            <form className="form-change-password needs-validation" id="change-password" noValidate>
                                <div className="row clearfix">
                                    <InputField col={3} name="current_password" id="current_password" placeholder="Current Password" required />
                                    <InputField col={3} name="new_password" id="new_password" placeholder="New Password" required />
                                    <InputField col={3} type="password" name="confirm_password" id="confirm_password" placeholder="Confirm Password" required />

                                    <div className="col-sm-12 mt-3 d-flex justify-content-end">
                                        <SubmitButton title={"Save Changes"} load={tinyloader} type="submit" className="btn btn-primary" name={'Add'} action={submitForm} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChangePassword