import { useEffect, useState } from "react";
import { DOCTOR_ADD, DOCTOR_UPDATE, MASTER_DROPDOWN } from "../../components/ApiRoutes";
import { InputField, ReactSelect, SubmitButton } from "../../components/FormHelper";
import { Breadcrumbs, getData, initialFormState, postData, validateForm } from "../../components/Helpers/Helper"
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";


const AddDoctor = () => {

    const [tinyloader, setTinyloader] = useState(false);
    const [date, setDate] = useState(null);
    const [gender, setGender] = useState();
    const [departmentDropdown, setDepartmentDropdown] = useState([])
    const [department, setDepartment] = useState('')
    const location = useLocation()
    const navigate = useNavigate();
    let data = location && location.state ? location.state : '';

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('doctor'));
            let apiurl = formdata.get('id') !== '' ? DOCTOR_UPDATE : DOCTOR_ADD;
            postData(apiurl, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res && res.status) {
                    initialFormState('doctor', true);
                    navigate("/manage-doctor")
                }
            })
        }
    }

    const getDepartment = () => {
        getData(`${MASTER_DROPDOWN}?type=department`, true, (res) =>{
            if(res && res.status){
                setDepartmentDropdown(res?.data)
            }
        });
    }

    useEffect(() => {
        setDate(location.state && location.state.dob ? new Date(location.state.dob) : null)

        if (location && location.state) {
            let gender = [{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }].filter((item) => item.value === location.state.gender)
            setGender(gender[0] ?? null)
        }
        getDepartment();

        if(data) setDepartment(data?.doctor_meta?.department_id)
        
    }, [location, data])

    return (
        <>
            <Breadcrumbs pageName={'Add Doctor'} parent={'Doctor'} />
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="header">
                            <h2>Doctor Information</h2>
                        </div>
                        <div className="body">
                            <form className="needs-validation form-add-doctor" id="doctor" noValidate>
                                <div className="row clearfix">
                                    <input type="text" name="id" id="id" className="form-control d-none" defaultValue={data.id} />
                                    <InputField col={4} name="first_name" id="first_name" defaultValue={data && data.first_name} placeholder="First Name" required />
                                    <InputField col={4} name="middle_name" id="middle_name" defaultValue={data.middle_name} placeholder="Middle Name" />
                                    <InputField col={4} name="last_name" id="last_name" defaultValue={data.last_name} placeholder="Last Name" required />
                                    <div className="mt-1 mx-0  text-start col-12 col-sm-6 col-md-3 col-lg-3">
                                        <label htmlFor="date" className="col-form-label pb-1">Date of Birth <sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="">
                                            <DatePicker wrapperClassName="w-100" className="form-control" placeholderText="YYYY-MM-DD" name="dob" id="dob" maxDate={new Date()} dateFormat="yyyy-MM-dd" selected={date} onChange={(d) => setDate(d)} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" required />
                                        </div>
                                    </div>
                                    <ReactSelect label="Gender" name="gender" id="gender" value={gender} size="col-lg-3 col-md-3 col-sm-6 mt-2" required onChange={(e) => setGender(e)} options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }]} />
                                    <InputField col={4} name="phone" id="phone" onKeyPress={e => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); } }} defaultValue={data.phone} placeholder="Phone" required />
                                    <InputField col={4} type="email" name="email" id="email" defaultValue={data.email} placeholder="Enter Your Email" required />
                                    {/* <InputField col={4} name="speciality" id="speciality" defaultValue={data?.doctor_meta?.speciality} placeholder="Speciality" required /> */}
                                    <ReactSelect label="Department" name="department" id="department" value={department} size="col-lg-3 col-md-3 col-sm-6 mt-2" required onChange={(e) => setDepartment(e)} options={departmentDropdown} />
                                    <InputField col={4} type="url" name="url" id="url" defaultValue={data?.doctor_meta?.website_url} label="Website URL" placeholder="Website URL" />
                                    <InputField col={4} name="address" id="address" defaultValue={data?.doctor_meta?.address} placeholder="Address" required />
                                    <InputField col={4} name="city" id="city" defaultValue={data?.doctor_meta?.city} placeholder="City" required />
                                    <InputField col={4} name="state" id="state" defaultValue={data?.doctor_meta?.state} placeholder="State" required />
                                    <InputField col={4} type="file" name="doctor_img" id="doctor_img" placeholder="File" label="Doctor Profile Picture" />
                                    <div className="col-sm-12 mt-2">
                                        <label htmlFor="description">About Doctor</label>
                                        <div className="form-group">
                                            <textarea rows="4" className="form-control no-resize" name="description" id="description" defaultValue={data?.doctor_meta?.description} placeholder="About Doctor..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card">
                                            <div className="header">
                                                <h2>Doctor's Account Information</h2>
                                            </div>
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <InputField col={3} name="username" id="username" defaultValue={data.username} placeholder="User Name" required readOnly={data && data.username ? true : false} />
                                                    <InputField col={3} type="password" name="password" id="password" defaultValue={data.password} placeholder="Password" required={data && data.id ? false : true} />
                                                    <InputField col={3} type="password" name="password_confirmation" id="password_confirmation" defaultValue={data.password_confirmation} placeholder="Confirm Password" required={data && data.id ? false : true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="card">
                                            <div className="header">
                                                <h2>Doctor's Social Media Info</h2>
                                            </div>
                                            <div className="body">
                                                <div className="row clearfix">
                                                    <div className="col-md-6">
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fa fa-facebook"></i></span>
                                                            </div>
                                                            <input type="text" name="facebook_link" id="facebook_link" defaultValue={data?.doctor_meta?.facebook_link} className="form-control" placeholder="Facebook" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fa fa-twitter"></i></span>
                                                            </div>
                                                            <input type="text" name="twitter_link" id="twitter_link" defaultValue={data?.doctor_meta?.twitter_link} className="form-control" placeholder="Twitter" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fa fa-linkedin"></i></span>
                                                            </div>
                                                            <input type="text" name="linkedin_link" id="linkedin_link" defaultValue={data?.doctor_meta?.linkedin_link} className="form-control" placeholder="Linkedin" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="input-group mb-3">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text"><i className="fa fa-instagram"></i></span>
                                                            </div>
                                                            <input type="text" name="instagram_link" id="instagram_link" defaultValue={data?.doctor_meta?.instagram_link} className="form-control" placeholder="Instagram" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <SubmitButton load={tinyloader} title={"Submit"} type="submit" className="btn btn-primary" name={'Add'} action={submitForm} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddDoctor