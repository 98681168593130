import { useEffect, useState } from "react"
import { DEPARTMENT_DELETE, DEPARTMENT_LIST } from "../../components/ApiRoutes"
import { Breadcrumbs, getData } from "../../components/Helpers/Helper"
import { useNavigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables"
import { ConfirmationModal, DeleteButton, EditButton } from "../../components/FormHelper";
import DepartmentDetails from "./DepartmentDetails";

const ManageDepartment = () => {
    const ASSET_URL = process.env.REACT_APP_ASSET_ENDPOINT

    const [reload, setReload] = useState(null);
    const [deleteRecord, setDeleteRecord]= useState(false)
    const [doctorDetails, setDoctorDetails]= useState(false)
    const navigate = useNavigate();

    const [dt] = useState({ 
        dt_url: DEPARTMENT_LIST,
        dt_name: 'department-list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "department_image", name: 'department_image', title: "Department Image" },
            { data: "name", name: 'name', title: "Department Name" },
            { data: "action", name: 'action', title: "Action", searchable: false, sortable: false },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(
                    <>
                    <div className="d-flex text-nowrap">
                        <img
                        src={`${records.department_image && ASSET_URL+records.department_image}`} 
                        className="border"
                        style={{ width: "65px", height: "65px" }}
                        alt="department_banner"
                        />
                    </div>
                    </>
                );
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(
                    <>
                        <div className="d-flex text-nowrap">
                            <EditButton action={(e) => navigate("/edit-department", { state: records })}/>
                            <button className="btn btn-outline-info ml-2" type="button" data-toggle="modal" data-target="#departmentDetails" onClick={() => setDoctorDetails(records)}>
                                <i className="fa fa-eye"></i>
                            </button>
                            <DeleteButton modal_id="department_delete" action={() => setDeleteRecord(records.id)} />
                        </div>
                    </>
                );
                },
            }
        ]
     
    })

    const deleteDepartment = (id, modal_id) => {
        getData(DEPARTMENT_DELETE + '/' + id, true, (res) => {
            if (res && res.success) {
                document.querySelector(`#${modal_id} [data-dismiss="modal"]`).click()
                setReload()
            }
        })
    }

    useEffect(() => {
        reloadDataTable(dt, DEPARTMENT_LIST)
    }, [dt, reload]);

    return (
        <>
            <Breadcrumbs pageName="Manage Department" parent="Department" />
            <div className="row">
                <div className="card px-3 py-4">
                    <DataTables dt={dt} dt_name="department-list" className="col-md-12" />
                </div>
            </div>
            <DepartmentDetails details={doctorDetails} />
            <ConfirmationModal modal_id="department_delete" action={() => deleteDepartment(deleteRecord, 'department_delete')} />
        </>
    );
}
export default ManageDepartment