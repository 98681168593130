import { Outlet, useNavigate } from "react-router-dom"
import Aside from "./Aside"
import Navbar from "./Navbar"
import { useContext, useEffect } from "react"
import Context from "../Helpers/Context"
import { scrollbarSetToTop } from "../Helpers/Helper"
import { FullScreen, useFullScreenHandle } from "react-full-screen"


export const NormalLayout = (props) => {

    return (
        <>
            <h1>NormalLayout Header</h1>
            <Outlet />
            <h1>NormalLayout Footer</h1>
        </>
    )
}

export const AuthLayout = () => {
    const navigate = useNavigate();
    const [context] = useContext(Context);
    const token = localStorage.getItem('hospital-token');
    const handle = useFullScreenHandle()

    useEffect(() => {
        scrollbarSetToTop()
        if (!context && !token) {
            navigate('/')
        }
        // eslint-disable-next-line
    }, [navigate, context]);



    return (
        <>
            {token && <>
                <FullScreen handle={handle}>
                <div id="wrapper" className="theme-cyan">
                    {/* <button type="button">FullScreen</button> */}
                        {/* <div className="page-loader-wrapper">
                            <div className="loader">
                                <div className="m-t-30"><img src="./assets/images/logo-icon.svg" width="48" height="48" alt="Iconic" /></div>
                                <p>Please wait...</p>
                            </div>
                        </div> */}
                        <Navbar fullscreen={handle} />
                        <Aside />
                        <div id="main-content">
                            <div className="container-fluid">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                    </FullScreen>
            </>}
        </>
    )   
}

export const UnAuthLayout = (props) => {
       const [context] = useContext(Context);
       const navigate = useNavigate();
       useEffect(() => {
        scrollbarSetToTop()
        if(context) {
            navigate('/dashboard')
        }
       }, [navigate, context])
    return (
        <>
        {!context  && <>
            {/* <h1>UnAuthLayout Header</h1> */}
            <Outlet />
            {/* <h1>UnAuthLayout Footer</h1> */}
        </>} 
        </>
    )
}

// export default Layout