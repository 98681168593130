import { InputField, SubmitButton } from "../../components/FormHelper"
import { initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import { ROLES_ADD, ROLES_UPDATE } from "../../components/ApiRoutes";
import { useState } from "react";
import { now } from "lodash";

const AddRoles = (props) => {

    const [loader, setLoader] = useState(false);

    const submitForm = (e) => {
        if (validateForm(e)) {
            setLoader(true)
            let formdata = new FormData(document.getElementById('add-role'));
            let apiurl =  formdata.get('id') !== '' ? ROLES_UPDATE : ROLES_ADD;
            postData(apiurl, 'POST', formdata, true, true, (res) => {
                setLoader(false)
                if (res && res.status) {
                    initialFormState('add-role', true);
                    props.reloadState(false)
                    document.querySelector("#roleDetails [data-dismiss='modal']").click()
                    props.load(now())

                }
            })
        }
    }


    return (
        <>
            <div className="modal fade" id="roleDetails" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog">
                    <form id="add-role" className="border rounded needs-validation" noValidate>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-5" id="exampleModalLabel">User Role</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.reloadState(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <input type="text" name="id" id="id" className="form-control d-none" defaultValue={props?.details?.id  }  />
                                <InputField col={1} name="name" id="name" placeholder="Role Name" defaultValue={props?.details?.name } required />
                            </div>
                            <div className="modal-footer mx-3">
                                <SubmitButton load={loader} title="Save" action={submitForm}/>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => props.reloadState(false)}>Close</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AddRoles
