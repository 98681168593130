import { useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Context from "../Helpers/Context";

const Aside = () => {

  const location = useLocation();
  const [context] = useContext(Context);
  const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "./assets/bundles/mainscripts.bundle.js";
    script.async = true;
    setTimeout(() => {
      document.body.appendChild(script);
    }, 1000)
  }, []);

  return (
    <>
      <div id="left-sidebar" className="sidebar">
        <button type="button" className="btn-toggle-offcanvas">
          <i className="fa fa-arrow-left"> </i>
        </button>
        <div className="sidebar-scroll">
          <div className="user-account">
            <img src={context?.auth?.role_id != 1 && context?.auth?.profile_image ? ASSET_ENDPOINT+context?.auth?.profile_image : './assets/images/user.png'} className="rounded-circle user-photo" alt="User Profile" />
            <div className="dropdown">
              <span>Welcome,</span>
              <Link to="/" className="user-name">
                <strong>
                {context?.auth?.first_name} {context?.auth?.middle_name} {context?.auth?.last_name}
                </strong>
              </Link>
            </div>
            <hr/>
          </div>

          {/* <!-- Tab panes --> */}
          <div className="tab-content padding-0">
            <div className="tab-pane active" id="menu">
              <nav id="left-sidebar-nav" className="sidebar-nav">
                {context && context.auth && context.auth.role_id == 1 &&
                  <ul className="metismenu li_animation_delay">
                    <li>
                      <NavLink to="/dashboard" activeclassname="selected">
                        <i className="fa fa-dashboard"></i>
                        <span>Dashboard</span>
                      </NavLink>
                    </li>
                    <li className={` ${['/roles', '/permission'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#" className="has-arrow" aria-expanded={`${['/roles', '/permission'].includes(location.pathname) ? 1 : 0}`}>
                        <i className="fa fa-users"></i>
                        <span>User Management</span>
                      </Link>
                      <ul aria-expanded={`${['/roles', '/permission'].includes(location.pathname) ? 1 : 0}`} className={`${['/roles', '/permission'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/roles" activeclassname="selected">Role</NavLink>
                        </li>
                        <li>
                          <NavLink to="/permission" activeclassname="selected">Permission</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink to="/manage-room" activeclassname="selected">
                        <i className="fa fa-bed"></i>
                        <span>Manage Room</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/labs-imaging" activeclassname="selected">
                        <i className="fa fa-building"></i>
                        <span>Labs & Imaging</span>
                      </NavLink>
                    </li>
                    <li className={` ${['/add-department', '/edit-department', '/manage-department'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#" className="has-arrow" aria-expanded={`${['/add-department', '/edit-department', '/manage-department'].includes(location.pathname) ? 1 : 0}`}>
                        <i className="fa fa-table"></i>
                        <span>Department</span>
                      </Link>
                      <ul aria-expanded={`${['/add-department', '/edit-department', '/manage-department'].includes(location.pathname) ? 1 : 0}`} className={`${['/add-department', '/edit-department', '/manage-department'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/add-department" activeclassname="selected">Add Department</NavLink>
                        </li>
                        <li>
                          <NavLink to="/manage-department" activeclassname="selected">Manage Department</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className={` ${['/add-doctor', '/manage-doctor'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#" className="has-arrow" aria-expanded={`${['/add-doctor', '/manage-doctor'].includes(location.pathname) ? 1 : 0}`}>
                        <i className="fa fa-user-md"></i>
                        <span>Doctor</span>
                      </Link>
                      <ul aria-expanded={`${['/add-doctor', '/manage-doctor'].includes(location.pathname) ? 1 : 0}`} className={`${['/add-doctor', '/manage-doctor'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/add-doctor" activeclassname="selected">Add Doctor</NavLink>
                        </li>
                        <li>
                          <NavLink to="/manage-doctor" activeclassname="selected">Manage Doctor</NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className={` ${['/add-patient', '/manage-patient'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#!" className="has-arrow" aria-expanded={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? true : false}`}>
                        <i className="fa fa-user"></i>
                        <span>Patient</span>
                      </Link>
                      <ul aria-expanded={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? true : false}`} className={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/add-patient" activeclassname="selected" >Add Patient </NavLink>
                        </li>
                        <li>
                          <NavLink to="/manage-patient">Manage Patient</NavLink>
                          </li> 
                      </ul> 
                    </li>
                    <li className={` ${['/user-profile', '/change-password'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#" className="has-arrow" aria-expanded={`${['/user-profile', '/change-password'].includes(location.pathname) ? 1 : 0}`}>
                        <i className="fa fa-cogs"></i>
                        <span>Settings</span>
                      </Link>
                      <ul aria-expanded={`${['/user-profile', '/change-password'].includes(location.pathname) ? 1 : 0}`} className={`${['/user-profile', '/change-password'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/user-profile" activeclassname="selected">User Profile</NavLink>
                        </li>
                        <li>
                          <NavLink to="/change-password" activeclassname="selected">Change Password</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                }
                {context && context.auth && context.auth.role_id == 2 &&
                  <ul className="metismenu li_animation_delay">
                      <li>
                        <NavLink to="/dashboard" activeclassname="selected">
                          <i className="fa fa-dashboard"></i>
                          <span>Dashboard</span>
                        </NavLink>
                      </li>
                      {context && context.auth && context.auth.permissions.includes('Manage Room') &&
                        <li>
                          <NavLink to="/manage-room" activeclassname="selected">
                            <i className="fa fa-bed"></i>
                            <span>Manage Room</span>
                          </NavLink>
                        </li>
                      }
                      {context && context.auth && (context.auth.permissions.includes('Add Patient') || context.auth.permissions.includes('Manage Patient')) &&
                        <li className={` ${['/add-patient', '/manage-patient'].includes(location.pathname) ? 'active' : ''}`}>
                          <Link to="#!" className="has-arrow" aria-expanded={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? true : false}`}>
                            <i className="fa fa-user"></i>
                            <span>Patient</span>
                          </Link>
                          <ul aria-expanded={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? true : false}`} className={`${['/add-patient', '/manage-patient'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                            {context.auth.permissions.includes('Add Patient') &&
                              <li>
                                <NavLink to="/add-patient" activeclassname="selected" >Add Patient </NavLink>
                              </li>
                            }
                            {context.auth.permissions.includes('Manage Patient') &&
                              <li>
                                <NavLink to="/manage-patient">Manage Patient</NavLink>
                              </li> 
                            }
                          </ul> 
                        </li>
                      }
                      <li className={` ${['/user-profile', '/change-password'].includes(location.pathname) ? 'active' : ''}`}>
                      <Link to="#" className="has-arrow" aria-expanded={`${['/user-profile', '/change-password'].includes(location.pathname) ? 1 : 0}`}>
                        <i className="fa fa-cogs"></i>
                        <span>Settings</span>
                      </Link>
                      <ul aria-expanded={`${['/user-profile', '/change-password'].includes(location.pathname) ? 1 : 0}`} className={`${['/user-profile', '/change-password'].includes(location.pathname) ? "collapse in" : "collapse"}`}>
                        <li>
                          <NavLink to="/user-profile" activeclassname="selected">User Profile</NavLink>
                        </li>
                        <li>
                          <NavLink to="/change-password" activeclassname="selected">Change Password</NavLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                }
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Aside








