import { useLocation } from "react-router-dom"
import { Breadcrumbs } from "../../components/Helpers/Helper"
import { useEffect, useState } from "react";

const ProfileDoc = () => {

    const location = useLocation()
    let data = location && location.state ? location.state : '';

    const [dt] = useState({
        dt_url: "",
        dt_name: 'list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "image", name: 'image', title: "Image" },
            { data: "name", name: 'name', title: "Name" },
            { data: "action", name: 'action', title: "Action", searchable: false, sortable: false },
        ]
    })

    useEffect(() => {
    }, [dt]);

    return (
        <>

            <Breadcrumbs pageName="Doctor Profile" parent="Doctor" />
            <div className="row clearfix">
                <div className="col-lg-4 col-md-12">
                    <div className="card profile-header">
                        <div className="body">
                            <div className="profile-image ">
                                <img src={process.env.REACT_APP_ASSET_ENDPOINT + data?.doctor_meta?.doctor_img} className="rounded-circle" alt="" />
                            </div>
                            <div>
                                <h4 className="m-b-0 text-body"><strong className="text-body">Dr. {data?.first_name}</strong> {data?.last_name}</h4>
                                <span>{data?.doctor_meta?.speciality}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="header">
                            <h2>Info</h2>
                        </div>
                        <div className="body">
                            <small className="text-muted">Address: </small>
                            <p>{data?.doctor_meta?.address}{data?.doctor_meta?.address && ', '}{data?.doctor_meta?.city}{data?.doctor_meta?.address && ', '}{data?.doctor_meta?.state}</p>
                            {/* <div>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1923731.7533500232!2d-120.39098936853455!3d37.63767091877441!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan+Francisco%2C+CA%2C+USA!5e0!3m2!1sen!2sin!4v1522391841133"
                                        width="100%" height="150" frameBorder="0" style={{border:"0"}}
                                        allowFullScreen></iframe>
                                </div> */}
                            <small className="text-muted">Email address: </small>
                            <p>{data?.email}</p>
                            <small className="text-muted">Mobile: </small>
                            <p>{data?.phone}</p>
                            <small className="text-muted">Birth Date: </small>
                            <p className="m-b-0">{data?.dob}</p>
                            <hr />
                            <small className="text-muted">Social: </small>
                            <p><i className="fa fa-twitter m-r-5"></i> {data?.doctor_meta?.twitter_link ? data?.doctor_meta?.twitter_link : 'NA'}</p>
                            <p><i className="fa fa-facebook  m-r-5"></i> {data?.doctor_meta?.twitter_link ? data?.doctor_meta?.facebook_link : 'NA'}</p>
                            <p><i className="fa fa-linkedin m-r-5"></i> {data?.doctor_meta?.twitter_link ? data?.doctor_meta?.linkedin_link : 'NA'}</p>
                            <p><i className="fa fa-instagram m-r-5"></i> {data?.doctor_meta?.twitter_link ? data?.doctor_meta?.instagram_link : 'NA'}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-md-12">

          <div className="card">
            <div className="header">
              <h2>Patients</h2>
             
            </div>
            <div className="body">
              <div className="table-responsive table_middel">
                <table className="table m-b-0">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr No.</th>
                      <th>Patient Profile</th>
                      <th>Patient Name</th>
                      <th>Doctor Name</th>
                      <th>Mobile No.</th>
                      <th>Admitted Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* { patientLatest && patientLatest.patient_count.map((val, index) => { */}
                      {/* return( */}
                      {/* {index} */}
                        <tr key="">
                        <td></td>
                        {/* {index+1} */}
                        <td>
                          {/* <img
                            src={val?.patient_img ? 
                              process.env.REACT_APP_ASSET_ENDPOINT + val?.patient_img :
                               './assets/images/user.png'}
                            className="rounded-circle avatar mr-2"
                            alt="Patient profile" />   */}
                        </td>
                        <td>
                          <span>
                           {/* {val?.user?.first_name} {val?.user?.last_name} */}
                          </span>
                        </td>
                        <td>
                          <span className="text-info">
                          {/* {val?.doctor_name?.label} */}
                          </span>
                        </td>
                        <td>
                          <span className="text-info">
                          {/* {val?.user?.phone} */}
                          </span>
                        </td>
                        {/* <td>{val?.admitted_date}</td> */}
                        <td>
                          <span className="text-info">  </span>
                          {/* {val?.user?.status} */}
                        </td>
                      </tr>
                      {/* )
                    })} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
         </div>

        </>
    )
}
export default ProfileDoc