import { useNavigate } from "react-router-dom"

const NotFound = () => {

    const navigate = useNavigate()

    return (
        <>
            <div id="wrapper" className="theme-cyan">
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main hospital">
                        <div className="auth-box">
                            <div className="card">
                                <div className="header">
                                    <h3>
                                        <span className="clearfix title">
                                            <span className="number left">404</span> <span className="text">Oops! <br />Page Not Found</span>
                                        </span>
                                    </h3>
                                </div>
                                <div className="body">
                                    <p>The page you were looking for could not be found.</p>
                                    <div className="margin-top-30">
                                        <button onClick={() => navigate(-1)} className="btn btn-default"><i className="fa fa-arrow-left"></i> <span>Go Back</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound