import { useLocation, useNavigate } from "react-router-dom"
import { DEPARTMENT_ADD, DEPARTMENT_UPDATE } from "../../components/ApiRoutes"
import { InputField, SubmitButton } from "../../components/FormHelper"
import { Breadcrumbs, initialFormState, postData, validateForm } from "../../components/Helpers/Helper"
import { useState } from "react"


const AddDepartment = () => {
    
    const location = useLocation()
    const navigate = useNavigate();
    let data = location && location.state ? location.state : '' ;

    const [tinyloader, setLoader] = useState(false)
    
    const submitForm = (e) => {
        if (validateForm(e)) {
            setLoader(true)
            let formdata = new FormData(document.getElementById('add-dept'));
            let apiurl =  formdata.get('id') !== '' ? DEPARTMENT_UPDATE : DEPARTMENT_ADD;
            postData(apiurl, 'POST', formdata, true, true, (res) => {
                setLoader(false)
                if (res && res.status) {
                    initialFormState('add-dept', true);
                    navigate("/manage-department")
                }
            })
        }
    }
   
    return (
        <>
            <Breadcrumbs pageName={data && data.id ? "Edit Department" : "Add Department"} parent="Department" />
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="header">
                            <h2>Department Information</h2>
                        </div>
                        
                        <div className="body">
                        <form className="form-add-dept needs-validation" id="add-dept" noValidate>
                            <div className="row clearfix">
                            <input type="text" name="id" id="id" className="form-control d-none" defaultValue={data.id }  />
                                <InputField col={2}  name="department_name" id="department_name" defaultValue={data.name} placeholder="Department Name"  required />
                                <InputField col={2} type="file" name="department_image" id="department_image" required={data && data.id ? false : true} />
                                <div className="mt-2 mx-0 text-start col-sm-md col-lg-12">
                                    <div className="form-group mt-3">
                                        <label htmlFor="description">Description <sup className="text-danger fw-bold fs-15px">*</sup></label>
                                        <textarea rows="6" className="form-control no-resize" name="description" id="description" defaultValue={data.description} placeholder="Description..." required></textarea>
                                        <div className="invalid-feedback">The Description field is required.</div>
                                    </div>
                                </div>
                                <div className="mt-2 mx-0 col-sm-md col-lg-12 d-flex justify-content-end">
                                    <SubmitButton load={tinyloader} title={"Submit"} type="submit" className="btn btn-primary" name={'Add'} action={submitForm} />
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddDepartment