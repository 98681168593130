import { startCase } from "lodash"
import Select from 'react-select'
import { useEffect, useState } from "react";

export const removeError = (e) => {
    if (e.target.nextElementSibling && e.target.nextElementSibling.classList.contains('d-block')) e.target.nextElementSibling.remove()
    e.target.classList.remove('is-invalid')
    e.target.classList.add('is-valid')
}

export const togglePassword = (e, id) => {
    let btn = e.target;
    let ele = document.getElementById(id);
    if (ele.getAttribute('type') === 'password') {
        ele.setAttribute('type', 'text');
        btn.innerHTML = '<i className="fa fa-eye-slash fs-5 pe-none"></i>'
    } else {
        ele.setAttribute('type', 'password');
        btn.innerHTML = '<i className="fa fa-eye fs-5 pe-none"></i>'
    }
}

export const InputField = (props) => {

    const [divClass, setDivClass] = useState("")

    useEffect(() => {
        if (props.col === 1) {
            setDivClass("row")
        } else if (props.col === 2) {
            setDivClass("col-sm-md col-lg-6 ")
        } else if (props.col === 3) {
            setDivClass("col-12 col-md-6 col-lg-4 ")
        } else if (props.col === 4) {
            setDivClass("col-12 col-sm-6 col-md-4 col-lg-3 ")
        } else if (props.col === 5) {
            setDivClass("col-sm-12")
        }
    }, [props.col])

    return (

        <div className={`mt-2 mx-0 text-start ${divClass}`}>
            <label htmlFor={props.id ?? props.name} className={props.col === 1 ? "col-sm-3 col-lg-6 col-form-label" : ""}>
                {props.label ?? startCase(props.name)} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <div className={`${props.col === 1 ? "col-sm-9 col-lg-12 col-xxl-5 " : " "} ${props.type === "password" ? "position-relative" : ""}`}>
                <input type={props.type ?? "text"} className="form-control" id={props.id ?? props.name} autoComplete="off" {...props} onInput={removeError} />
                {props.suggestion && <span className="text-success fs-12px">{props.suggestion}</span>}
                <div className="invalid-feedback">The {props.label ?? startCase(props.name)} field is required.</div>
                {props.type === "password" && <>
                    <button type="button" onClick={(e) => togglePassword(e, props.id ?? props.name)} className="btn position-absolute px-1 mr-2 top-7 end-0 translate-middle-y mt-1 pt-3 shadow-none">
                        <i className="fa fa-eye fs-5 pe-none"></i>
                    </button>
                </>}
                {props.hint &&
                    <small className="text-secondary fst-italic">{props.hint}</small>
                }
            </div>
        </div>
    )
}

export const SubmitButton = (props) => {

    return (
        <div className="row mx-0">
            <div className="p-0">
                <button type={props.type ?? "button"} className={`btn btn-${props.bgColor ?? "primary"} btn-hover-dark`} disabled={props.load} title={props.title} onClick={props.action}>
                    {props.load ? <div className="spinner-border spinner-border-sm mx-3" role="status"><span className="visually-hidden">Loading</span></div> : props.title}
                </button>
            </div>
        </div>
    )
}


export const UnAuthInputField = (props) => {

    return (
        <div className="form-floating mb-3">
            <input type="text" className="form-control" id={props.id ?? props.name} name={props.name} {...props} onInput={removeError} />
            <label htmlFor="floatingEmail">{props.label ?? startCase(props.name)}{props.required && "*"}</label>
            <div className="invalid-feedback">The {props.label ?? startCase(props.name)} field is required.</div>
        </div>
    )
}

export const UnAuthPasswordField = (props) => {

    return (
        <div className="form-floating mb-3">
            <input type="password" className="form-control" id={props.id ?? props.name} name={props.name} {...props} onInput={removeError} />
            <label htmlFor="floatingEmail">{props.label ?? startCase(props.name)}{props.required && "*"}</label>
            <div className="invalid-feedback">The {props.label ?? startCase(props.name)} field is required.</div>
            <button type="button" onClick={(e) => togglePassword(e, props.id ?? props.name)} className="btn position-absolute px-1 me-4 top-0 end-0 translate-middle-y mt-3 pt-3 shadow-none">
                <i className="bi-eye fs-5 pe-none"></i>
            </button>
        </div>
    )
}

InputField.propTypes = null

export const  ReactSelect = (props) => {
    return (
        <div className={props.size ? props.size : 'col-lg-4 col-md-6 mb-3'}>
            <label htmlFor={props?.name} className="col-form-label">
                {props.label} {props.required && <sup className='text-danger fw-bold fs-15px'>*</sup>}
            </label>
            <Select isMulti={props?.isMulti} className="react-select customSelect " id={props.id ?? props.name} onInputChange={() => removeReactSelectError(props.id ?? props.name)} {...props} />
        </div>
    )
}

export const removeReactSelectError = (id) => {
    let ele = document.querySelector(`#${id}`)
    if (ele.lastChild.classList.contains('d-block')) ele.lastChild.remove();
    ele.classList.remove('is-invalid')
    ele.classList.add('is-valid')
}

export const EditButton = (props) => {
    return (
        <>
            <button type="button" className="btn btn-outline-primary" onClick={props.action} data-toggle="modal" data-target={`#${props.modal_id}`}>
                <i className="fa fa-pencil"></i>
            </button>
        </>
    )
}

export const DeleteButton = (props) => {
    return (
        <>
            <button type="button" className="btn btn-outline-danger ml-2" onClick={props.action} data-toggle="modal" data-target={`#${props.modal_id}`}>
                <i className="fa fa-trash"></i>
            </button>
        </>
    )
}

export const ConfirmationModal = (props) => {
    return (
        <>
            <div className="modal fade" id={props.modal_id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <h4>Are you sure want to delete this record?</h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={props.action}>Delete</button>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}