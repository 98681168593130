import { MASTER_DROPDOWN, PATIENT_ADD, PATIENT_UPDATE } from "../../components/ApiRoutes";
import { InputField, SubmitButton, ReactSelect } from "../../components/FormHelper";
import { Breadcrumbs, getData, initialFormState, postData, validateForm } from "../../components/Helpers/Helper";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AddPatient = () => {

    const [selectedDropdown, setSelectedDropDown] = useState({doctor_name: null, room_no: null })
    const [date, setDate] = useState(null);
    const [admitDate, setAdmitDate] = useState(null);
    const [tinyloader, setTinyloader] = useState(false);
    const [gender, setGender] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    let data = location && location.state ? location.state : '';
    const [dropDownData, setDropDownData] = useState({
        doctor_name: [],
        room_no: []
    })


    const saveForm = (e) => {

        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('add-patient'));
            let apiurl = formdata.get('id') !== '' ? PATIENT_UPDATE : PATIENT_ADD;
            postData(apiurl, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res && res.status) {
                    initialFormState('add-patient', true);
                    navigate("/manage-patient");
                }
            })
        }
    }

    useEffect(() => {
    

        getData(`${MASTER_DROPDOWN}?type=doctor`, true, (res) => {
            if (res.status) {
                setDropDownData(prevState => ({ ...prevState, doctor_name: res.data }))
                if (location) {
                    setSelectedDropDown(prevState => ({ ...prevState, doctor_name: data?.patient?.doctor_name }))
                }
            }
        })

        getData(`${MASTER_DROPDOWN}?type=room`, true, (res) => {
            if (res.status) {
                setDropDownData(prevState => ({ ...prevState, room_no: res.data }))
                if (location) {
                    setSelectedDropDown(prevState => ({ ...prevState, room_no: data?.patient?.ward_name }))
                }
            }
        })


        setDate(data?.dob ? new Date(data?.dob) : null)
        setAdmitDate(data?.patient?.admitted_date ? new Date(data?.patient?.admitted_date) : null)

        if (location && location.state) {
            let gender = [{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }].filter((item) => item.value === location.state.gender)
            setGender(gender[0] ?? null)
        }
    }, [location, data])

    return (
        <>
            <Breadcrumbs pageName={'Add Patient'} parent={'Patient'} />
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="header">
                            <h2>Patient Information </h2>
                        </div>
                        <div className="body">
                            <form className="form-add-patient needs-validation" id="add-patient" noValidate>
                                <div className="row clearfix mt-2">
                                    <input type="text" name="id" id="id" className="form-control d-none" defaultValue={data.id} />
                                    <InputField col={4} name="first_name" id="first_name" defaultValue={data?.first_name} placeholder="First Name" required />
                                    <InputField col={4} name="last_name" id="last_name" defaultValue={data?.last_name} placeholder="Last Name" required />
                                    <InputField col={4} onKeyPress={e => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); } }} name="phone" id="phone" pattern="^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$" defaultValue={data?.phone} placeholder="Phone" required />
                                    <InputField col={4} onKeyPress={e => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); } }} name="age" id="age" defaultValue={data?.patient?.age} placeholder="Age" required />
                                </div>
                                <div className="row clearfix mt-2">
                                    <InputField col={4} name="email" id="email" defaultValue={data?.email} placeholder="Email" required />
                                    <div className="mt-2 mx-0 text-start col-12 col-sm-6 col-md-4 col-lg-3" >
                                        <label htmlFor="dob">Date of Birth </label>
                                        <DatePicker wrapperClassName="form-control w-100" className="form-control" placeholderText="YYYY-MM-DD" name="dob" id="dob" maxDate={new Date()} dateFormat="yyyy-MM-dd" selected={date} onChange={(d) => setDate(d)} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" required />
                                    </div>
                                    <ReactSelect label="Gender" name="gender" id="gender" value={gender} size="mx-0 text-start col-12 col-sm-6 col-md-4 col-lg-3" required onChange={(e) => setGender(e)}
                                            options={[{ value: "male", label: "Male" }, { value: "female", label: "Female" }, { value: "other", label: "Other" }]} />
                                    <InputField col={4} type="file" label={'Profile Image'} name="patient_img" id="patient_img" placeholder="File" />
                                </div>
                                <div className="row clearfix mt-2">
                                    <div className="col-sm-12">
                                        <label htmlFor="description">About Patient <sup className='text-danger fw-bold fs-15px'>*</sup></label>
                                        <div className="form-group m-t-10">
                                            <textarea rows="4" className="form-control no-resize" name="description" id="description" defaultValue={data?.patient?.description} placeholder="About Patient..." required></textarea>
                                            <div className="invalid-feedback">The Description field is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix mt-2">
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="header">
                                                <h2>Patient Onboard Details</h2>
                                            </div>
                                            <div className="body">
                                                <div className="row clearfix">
                                                <ReactSelect size="col-12 col-md-6 col-lg-4 " name="doctor_name" id="doctor_name" value={selectedDropdown.doctor_name} label="Doctor Name" options={dropDownData.doctor_name}  onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, doctor_name: e }))}  required />
                                                <ReactSelect size="col-12 col-md-6 col-lg-4 " name="ward_no" id="ward_no" value={selectedDropdown.room_no} label="Room No." options={dropDownData.room_no}  onChange={(e) => setSelectedDropDown(prevState => ({ ...prevState, room_no: e }))} required />
                                                    <div className="col-12 col-md-6 col-lg-4 mt-1" >
                                                        <label htmlFor="admitted_date" >Admitted Date</label>
                                                        <div className="">
                                                            <DatePicker wrapperClassName="form-control w-100" className="form-control" placeholderText="YYYY-MM-DD" name="admitted_date" id="admitted_date"
                                                                dateFormat="yyyy-MM-dd" maxDate={new Date()} selected={admitDate} onChange={(d) => setAdmitDate(d)} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 d-flex justify-content-end">
                                    <SubmitButton load={tinyloader} title={"Submit"} type="submit" className="btn btn-primary" action={saveForm} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddPatient






