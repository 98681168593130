import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Context from "../Helpers/Context";
import { getData } from "../Helpers/Helper";
import { LOG_OUT } from "../ApiRoutes";

const Navbar = ({fullscreen}) => {
  // eslint-disable-next-line
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    getData(LOG_OUT, true, (res) => {
      if (res.status) {
        setContext("");
        localStorage.removeItem("hospital-token");
        navigate("/");
      }
    });
  };
  return (
    <>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-brand">
            <button type="button" className="btn-toggle-offcanvas">
              <i className="fa fa-bars"></i>
            </button>
            <button type="button" className="btn-toggle-fullwidth">
              <i className="fa fa-bars"></i>
            </button>
            <Link to="/dashboard"> Hospital CRM</Link>
          </div>

          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <button className="icon-menu btn btn-outline-default" onClick={fullscreen.active ? fullscreen.exit : fullscreen.enter}>
                    {fullscreen.active ?
                      <i className="fa fa-compress"></i>
                      :
                      <i className="fa fa-expand"></i>
                    }
                  </button>
                </li>
                <li>
                  <button
                    className="icon-menu btn btn-outline-default"
                    onClick={(e) => handleLogout(e)}
                  >
                    <i className="fa fa-power-off"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar
