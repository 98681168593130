import React from "react";
import { Breadcrumbs, getData } from "../../components/Helpers/Helper";
import { useEffect, useState } from "react";
import { GET_COUNT, GET_PATIENT } from "../../components/ApiRoutes";

const Dashboard = () => {
  const [data, setData] = useState()
  const [patientLatest, setPatientLatest] = useState()

  const all_records = () => {

    getData(GET_COUNT, true, (res) => {
      if (res && res.data) {
        setData(res.data)

      } else {
        setData([])
      }
    })
  }

  const all_patient = () => {

    getData(GET_COUNT, true, (res) => {
      if (res && res.data) {
        setData(res.data)

      } else {

        setData([])

      }
    })
  }

  const get_patient = () => {

    getData(GET_PATIENT, true, (res) => {
      if (res && res.data) {
        setPatientLatest(res.data)

      }
    })

  }

  useEffect(() => {

    all_patient();
    all_records();
    get_patient();

  }, [])

  return (
    <>
      <Breadcrumbs pageName="Dashboard" />
      <div className="row clearfix row-deck">
        <div className="col-xl-3 col-lg-4 col-md-12">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-6">
              <div className="card top_widget">
                <div id="top_counter1" className="carousel slide" data-ride="carousel" data-interval="2500" >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="body">
                        <div className="icon">
                          <i className="fa fa-user"> </i>
                        </div>
                        <div className="content">
                          <div className="text mb-2 text-uppercase">
                            Total Patient
                          </div>
                          <h4 className="number mb-0">

                            {data?.patient_count ?? 0}{" "}

                          </h4>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="card top_widget">
                <div
                  id="top_counter2"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="2000" >

                  <div className="carousel-inner">

                    <div className="carousel-item active">
                      <div className="body">
                        <div className="icon">
                          <i className="fa fa-user-md"></i>
                        </div>
                        <div className="content">
                          <div className="text mb-2 text-uppercase">
                            Total Doctors
                          </div>
                          <h4 className="number mb-0">
                            {data?.doctor_count ?? 0}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="card top_widget">
                <div
                  id="top_counter3"
                  className="carousel slide"
                  data-ride="carousel"
                  data-interval="2300" >

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="body">
                        <div className="icon">
                          <i className="fa fa-eye"></i>
                        </div>
                        <div className="content">
                          <div className="text mb-2 text-uppercase">
                            Available Room
                          </div>
                          <h4 className="number mb-0">
                            {data?.room_count ?? 0}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="card top_widget">
                <div id="top_counter4" className="carousel slide" data-ride="carousel" data-interval="2300" >

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="body">
                        <div className="icon">
                          <i className="fa fa-thumbs-o-up"></i>
                        </div>
                        <div className="content">
                          <div className="text mb-2 text-uppercase">
                            Total Departments
                          </div>
                          <h4 className="number mb-0">{data?.department_count ?? 0}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-9 col-lg-8 col-md-12">
          <div className="card">
            <div className="header">
              <h2>New Patients</h2>

            </div>
            <div className="body">
              <div className="table-responsive table_middel">
                <table className="table m-b-0">
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr No.</th>
                      <th>Patient Profile</th>
                      <th>Patient Name</th>
                      <th>Doctor Name</th>
                      <th>Mobile No.</th>
                      <th>Admitted Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientLatest && patientLatest.patient_count.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={val?.patient_img ?
                                process.env.REACT_APP_ASSET_ENDPOINT + val?.patient_img :
                                './assets/images/user.png'}
                              className="rounded-circle avatar mr-2"
                              alt="Patient profile" />
                          </td>
                          <td>
                            <span>
                              {val?.user?.first_name} {val?.user?.last_name}
                            </span>
                          </td>
                          <td>
                            <span className="text-info">
                              {val?.doctor_name?.label}
                            </span>
                          </td>
                          <td>
                            <span className="text-info">
                              {val?.user?.phone}
                            </span>
                          </td>
                          <td>{val?.admitted_date}</td>
                          <td>
                            <span className="text-info">  {val?.user?.status}</span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}
export default Dashboard


