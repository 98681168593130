import { useEffect, useState } from "react";
import { PERMISSION_LIST } from "../../components/ApiRoutes";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables";
import { Breadcrumbs } from "../../components/Helpers/Helper";
import { createRoot } from "react-dom/client";
import { EditButton } from "../../components/FormHelper";
import AddPermission from "./AddPermission";


const Permission = () => {

    const [reload, setReload] = useState(null);
    const [permissionUpdate, setPermissionUpdate] = useState(false)
    const [dt] = useState({
        dt_url: PERMISSION_LIST,
        dt_name: "permission-list",
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "name", name: 'name', title: "Permission Name" },
            { data: "action", name: 'action', title: "Action", searchable: false, sortable: false },
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                            <EditButton modal_id="permissionDetails" action={() => setPermissionUpdate(records)} />
                            </div>
                        </>
                    );
                },
            }
        ],
        dt_filter: () => {
            createRoot(document.querySelector(`#adb_datatable_permission-list_wrapper #dt-custom-filter`)).render(<>
                <button type="button" style={{height: "38px"}} className="btn btn-sm btn-primary mr-2"  title="Add Role" data-toggle="modal" data-target="#permissionDetails">
                    Add Permission
                </button>
                
            </>)
        }
    })
  

    useEffect(() => {
        reloadDataTable(dt, PERMISSION_LIST)
    }, [dt, reload]);
    return (
        <>
            <Breadcrumbs pageName="Permission" parent="User Management" />
            <div className="row">
                <div className="card px-3 py-4">
                    <div className="col">
                        <DataTables dt={dt} dt_name="permission-list" className="col-md-12" />
                    </div>
                </div>
            </div>
            <AddPermission details={permissionUpdate} reloadPermission={setPermissionUpdate} load={setReload} />
        </>
    )
}
export default Permission
