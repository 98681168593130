
import {  ReactSelect, SubmitButton } from "../../components/FormHelper"
import { getData, initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import {  MASTER_DROPDOWN, PERMISSION_ASSIGN } from "../../components/ApiRoutes";
import { useEffect, useState } from "react";
import { now } from "lodash";


const AssignPermission = (props) => {

    const [permissionDropdown, setPermissionDropdown] = useState([])
    const [permission, setPermission] = useState('')
    const [tinyloader, setTinyloader] = useState(false)

    const assignPermission = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('assign-permission'));
            postData(PERMISSION_ASSIGN, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res && res.status) {
                    initialFormState('assign-permission', true);
                    document.querySelector("#assignPermission [data-dismiss='modal']").click()
                    props.loadData(now)
                }
            })
        }
    }

    const getPermission = () => {
        getData(`${MASTER_DROPDOWN}?type=permission`, true, (res) =>{
            if(res ){
                setPermissionDropdown(res?.data)
                
            }
        });
    }
    useEffect(() => {
        getPermission();
        setPermission(props?.details?.assigned_permissions)
        
    }, [props])

    return (
        <>
            <div className="modal fade" id="assignPermission" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                <div className="modal-dialog">
                    <form id="assign-permission" className="border rounded needs-validation" noValidate>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-5" id="exampleModalLabel">User Permission</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <input type="text" name="user_id" id="user_id" className="form-control d-none" defaultValue={props?.details?.doctor_meta?.user_id}  />
                                <ReactSelect size="col-lg-12 col-md-12 col-sm-12" name="permission_name[]" label="Permission Name" isMulti id="permission_name" placeholder="Role Name" value={permission} onChange={(e) => setPermission(e)} options={permissionDropdown} />
                            </div>
                            <div className="modal-footer mx-3">
                                <SubmitButton load={tinyloader} title="Save" action={assignPermission}/>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default AssignPermission
