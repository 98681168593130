import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables";
import { Breadcrumbs } from "../../components/Helpers/Helper"
import { LAB_LIST } from "../../components/ApiRoutes";
import { createRoot } from "react-dom/client";
import _ from "lodash"
import { useNavigate } from "react-router-dom";

const LabsImagingList = () => {

    const navigate = useNavigate()

    const [reload, setReload] = useState(false)

    const [dt] = useState({
        dt_url: LAB_LIST,
        dt_name: 'labs-imaging-list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "lab_id", name: 'lab_id', title: "Lab ID" },
            { data: "patient_data.label", name: 'patient_data.label', title: "Patient Name" },
            { data: "doctor_data.label", name: 'doctor_data.label', title: "Doctor Name" },
            { data: "lab_type", name: 'lab_type', title: "Lab Type" },
            { data: "work_status", name: 'work_status', title: "Work Status" },
            { data: "lab_document", name: 'lab_document', title: "Lab Document" },
            { data: "report_document", name: 'report_document', title: "Lab Report" },
            { data: "action", name: 'action', title: "Action", searchable: false, sortable: false },
        ],
        dt_column_defs: [
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.lab_type && _.toUpper(records.lab_type)}
                            </div>
                        </>
                    )
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.work_status && _.startCase(_.replace(records.work_status, '_', ' '))}
                            </div>
                        </>
                    )
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.lab_document ?
                                    ''
                                    : 'N/A'}
                            </div>
                        </>
                    )
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.report_document ? '' : 'N/A'}
                            </div>
                        </>
                    )
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                <button type="button" onClick={() => navigate('/edit-labs-imaging', { state: records })} className="btn btn-outline-primary">
                                    <i className="fa fa-pencil"></i>
                                </button>
                            </div>
                        </>
                    )
                }
            },
        ],
        dt_filter: () => {
            createRoot(document.querySelector(`#adb_datatable_labs-imaging-list_wrapper #dt-custom-filter`)).render(<>
                <button type="button" style={{ height: "38px" }} className="btn btn-sm btn-primary mr-2" title="Add Labs & Imaging" onClick={() => navigate('/add-labs-imaging')}>
                    Add Labs & Imaging
                </button>
            </>)
        }
    });

    useEffect(() => {
        reloadDataTable(dt)
    }, [dt, reload])

    return (
        <>
            <Breadcrumbs pageName="Labs & Imaging" />
            <div className="row">
                <div className="card px-3 py-4">
                    <DataTables dt={dt} dt_name="labs-imaging-list" className="col-md-12" />
                </div>
            </div>
        </>
    )
}

export default LabsImagingList;