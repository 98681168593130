import { useEffect, useState } from "react"
import { DOCTOR_DELETE, DOCTOR_LIST } from "../../components/ApiRoutes"
import { Breadcrumbs, getData } from "../../components/Helpers/Helper"
import { Link, useNavigate } from "react-router-dom"
import AssignPermission from "./AssignPermission"
import { ConfirmationModal } from "../../components/FormHelper"
import { now } from "lodash";

const ManageDoctor = () => {
    const [data, setData] = useState(false)
    const [reload, setReload] = useState(null);
    const [permissionUpdate, setPermissionUpdate] = useState(null);
    const [deleteRecord, setDeleteRecord] = useState(false)
    const navigate = useNavigate();

    const all_doctor = () => {
        getData(DOCTOR_LIST, true, (res) => {
            if (res && res?.data?.length > 0) {
                setData(res.data)
            }else{
                setData([])
            }
        })
    }
    const delDoctor = (id, modal_id) => {
        getData(DOCTOR_DELETE + '/' + id, true, (res) => {
            if (res && res.success) {
                document.querySelector(`#${modal_id} [data-dismiss="modal"]`).click()
                setReload(now)
            }
        })
    }

    useEffect(() => {

        all_doctor();

    }, [reload]);

    return (
        <>
        
            <Breadcrumbs pageName={'Doctor List'} parent={'Doctor'} />
            <div className="row clearfix">
                {data && data.length > 0 ? data.map((val, index) => {
                    return (
                        <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                            <div className="card">
                                <div className="body text-center">
                                    <div className="dropdown align-right">
                                        <button className="btn btn-primary " type="button" data-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu text-center">
                                            <li className="cursor-pointer border-bottom" onClick={(e) => navigate("/doctor-profile", { state: val })}>View</li>
                                            <li className="cursor-pointer border-bottom" onClick={(e) => navigate("/add-doctor", { state: val })}>Edit</li>
                                            <li className="cursor-pointer border-bottom"  data-toggle="modal" data-target="#assignPermission" onClick={() => setPermissionUpdate(val)}>Permission</li>
                                            <li className="cursor-pointer" data-toggle="modal" data-target="#doctor_delete" onClick={() => setDeleteRecord(val.id)} >Delete</li>
                                        </ul>
                                    </div>

                                    <div className="chart easy-pie-chart-1" data-percent="75">
                                        <span>
                                            <img src={val?.doctor_meta?.doctor_img ? process.env.REACT_APP_ASSET_ENDPOINT + val?.doctor_meta?.doctor_img : './assets/images/user.png'} className="doctor-image w-100 h-100" title={`${val.first_name} ${val.last_name}`} alt="Doctor Profile" />
                                        </span>
                                    </div>

                                    <h6 className="mb-0"><span className="cursor-pointer doctor-name text-info" onClick={(e) => navigate("/doctor-profile", { state: val })} title={`${val.first_name} ${val.last_name}`} >Dr. {`${val.first_name} ${val.last_name}`}</span> </h6>
                                    <span>{val?.doctor_meta?.department_id?.label}</span>
                                    <ul className="social-links list-unstyled d-flex justify-content-center mt-3">
                                        <li className="ml-2 mr-2"><Link title="facebook" to={val?.doctor_meta?.facebook_link} className={!val?.doctor_meta?.facebook_link ? 'disabled' : ''}><i className="fa fa-facebook"></i></Link></li>
                                        <li className="ml-2 mr-2"><Link title="twitter" to={val?.doctor_meta?.twitter_link} className={!val?.doctor_meta?.twitter_link ? 'disabled' : ''}><i className="fa fa-twitter"></i></Link></li>
                                        <li className="ml-2 mr-2"><Link title="linkedin" to={val?.doctor_meta?.linkedin_link} className={!val?.doctor_meta?.linkedin_link ? 'disabled' : ''}><i className="fa fa-linkedin"></i></Link></li>
                                        <li className="ml-2 mr-2"><Link title="instagram" to={val?.doctor_meta?.instagram_link} className={!val?.doctor_meta?.instagram_link ? 'disabled' : ''} ><i className="fa fa-instagram"></i></Link></li>
                                    </ul>
                                    <span>
                                        {val?.doctor_meta?.address}{val?.doctor_meta?.address && ', '}{val?.doctor_meta?.city}{val?.doctor_meta?.address && ', '}{val?.doctor_meta?.state}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }) : 
                    <>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="card">
                                <div className="body">
                                    <div className="col-md-12 text-center">
                                        <span style={{fontSize: "120px"}}>
                                            <i className="fa fa-cube"></i>
                                        </span>
                                        <h4>No record found!</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
            </div>
            <AssignPermission details={permissionUpdate} loadData={setReload} />
            <ConfirmationModal modal_id="doctor_delete" action={() => delDoctor(deleteRecord, 'doctor_delete')} />
        </>
    )
}

export default ManageDoctor








