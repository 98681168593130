
// Auth Routes
export const LOGIN = "login"
export const FORGET_PASSWORD = "forgot-password"
export const CHANGE_PASSWORD = "change-password"
export const RESET_PASSWORD = "reset-password"
export const AUTH_USER_INFO = "get-auth-user-info"
export const USER_UPDATE = "update-user"
export const LOG_OUT = "log-out"
export const DEPARTMENT_ADD = "add-department"
export const DEPARTMENT_UPDATE = "edit-department" 
export const DEPARTMENT_LIST = "department-list"
export const DEPARTMENT_DELETE = "delete-department"
export const DOCTOR_ADD = "doctor-add"
export const DOCTOR_LIST = "doctor-list"
export const DOCTOR_UPDATE = "update-doctor"
export const DOCTOR_DELETE = "delete-doctor"

export const PATIENT_ADD = "add-patient"
export const PATIENT_UPDATE = "patient-update"
export const PATIENT_LIST = "patient-list"
export const PATIENT_DELETE = "delete-patient"

// Master Dropdown
export const MASTER_DROPDOWN = "master-dropdown"


export const ROLES_LIST = "role-list"
export const ROLES_ADD = "create-role"
export const ROLES_UPDATE = "update-role"

export const PERMISSION_LIST = "permission-list"
export const PERMISSION_CREATE = "create-permission"
export const PERMISSION_UPDATE = "update-permission"
export const PERMISSION_ASSIGN = "assign-permission"


export const ROOM_LIST = 'list-room'
export const ADD_ROOM = 'add-room'
export const UPDATE_ROOM = 'update-room'
export const DELETE_ROOM = 'delete-room'


export const ADD_LAB = 'add-lab'
export const UPDATE_LAB = 'update-lab'
export const LAB_LIST = 'lab-list'

export const GET_COUNT = 'count-data'
export const GET_PATIENT = 'patient-count'





