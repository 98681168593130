import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables";
import { Breadcrumbs, getData } from "../../components/Helpers/Helper"
import { DELETE_ROOM, ROOM_LIST } from "../../components/ApiRoutes";
import { ConfirmationModal, DeleteButton } from "../../components/FormHelper";
import { createRoot } from "react-dom/client";
import AddUpdateRoom from "./partial/AddUpdateRoom";
const ManageRoom = () => {

    const [reload, setReload] = useState(false)
    const [record, setRecord] = useState(false)
    const [deleteRecord, setDeleteRecord] = useState(false)

    const [dt] = useState({
        dt_url: ROOM_LIST,
        dt_name: 'room-list',
        dt_column:[
            {data:"DT_RowIndex",name:"id",title:"SR.NO"},
            {data:"room_no",name:"room_no",title:"Room No"},
            {data:"room_type" ,name:"room_type",title:"Room Type"},
            {data:"action",name:"action",title:"Action"},
        ],
        dt_column_defs: [
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                        <div className="d-flex text-nowrap">
                            <button className="btn btn-outline-primary" type="button" data-target="#roomModal" data-toggle="modal" onClick={() => setRecord(records)}>
                                <i className="fa fa-pencil"></i>
                            </button>
                            <DeleteButton modal_id="room_delete" action={() => setDeleteRecord(records.id)} />
                        </div>
                        </>
                    );
                },
            }
        ],
        dt_filter: () => {
            createRoot(document.querySelector(`#adb_datatable_room-list_wrapper #dt-custom-filter`)).render(<>
                <button type="button" style={{height: "38px"}} className="btn btn-sm btn-primary mr-2"  title="Add Room" data-toggle="modal" data-target="#roomModal" onClick={() => setRecord('')}>
                    Add Room
                </button>
                
            </>)
        }
    });

    const deleteRoom = (record_id, modal_id) => {
        getData(`${DELETE_ROOM}/${record_id}`, true, (res) => {
            if(res && res.success){
                setReload(true)
                document.querySelector(`#${modal_id} [data-dismiss="modal"]`).click()
            }
        })
    } 

    useEffect(() => {
        reloadDataTable(dt, ROOM_LIST)
    }, [dt, reload]);

    return (
        <>
            <Breadcrumbs pageName="Manage Room" />
            <div className="row clearfix">
                <div className="card px-3 py-4">
                    <DataTables dt={dt} dt_name="room-list" className="col-md-12" />
                </div>
            </div>

            <AddUpdateRoom details={record} loading={setReload} />
            <ConfirmationModal modal_id="room_delete" action={() => deleteRoom(deleteRecord, 'room_delete')} />
        </>
    )

}

export default ManageRoom;