import { useEffect, useState } from "react"
import { PATIENT_DELETE, PATIENT_LIST } from "../../components/ApiRoutes"
import { useNavigate } from "react-router-dom"
import { Breadcrumbs, getData } from "../../components/Helpers/Helper";
import { createRoot } from "react-dom/client";
import { ConfirmationModal, DeleteButton, EditButton } from "../../components/FormHelper";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables";
import _ from 'lodash'

const AllPatient = () => {

    const ASSET_URL = process.env.REACT_APP_ASSET_ENDPOINT

    const [reload, setReload] = useState(null);
    const [deleteRecord, setDeleteRecord] = useState(false)
    const navigate = useNavigate();

    const [dt] = useState({
        dt_url: PATIENT_LIST,
        dt_name: 'manage-patient',
        dt_column: [
            { data: "DT_RowIndex", name: "id", title: "SR.NO" , searchable: false, sortable: false},
            { data: "patient_img", name: "patient_img", title: "Patient Image", searchable: false, sortable: false},
            { data: "first_name", name: "first_name", title: " Name" },
            { data: "email", name: "email", title: "Email" },
            { data: "gender", name: "gender", title: "Gender" },
            { data: "dob", name: "dob", title: "Date Of Birth" },
            { data: "doctor_name", name: "doctor_name", title: "Doctor Name" },
            { data: "action", name: "action", title: "Action", searchable: false, sortable: false },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, celldata, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                <img src={`${records?.patient?.patient_img && ASSET_URL + records?.patient?.patient_img}`}
                                    className="border" style={{ width: "65px", height: "65px" }} alt="patient_image" />
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 2,
                createdCell: (td, celldata, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                              {records?.first_name} {records?.last_name}
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 4,
                createdCell: (td, celldata, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.gender && _.capitalize(records.gender)}
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 6,
                createdCell: (td, celldata, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                {records?.patient?.doctor_name?.label}
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 7,
                createdCell: (td, celldata, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                <EditButton action={(e) => navigate("/add-patient", { state: records })} />
                                <DeleteButton modal_id="patient_delete" action={() => setDeleteRecord(records.id)} />
                            </div>
                        </>
                    );
                },
            },
        ],

    })

    const deletePatient = (id, modal_id) => {
        getData(PATIENT_DELETE + '/' + id, true, (res) => {
            if (res && res.success) {
                document.querySelector(`#${modal_id} [data-dismiss="modal"]`).click()
                setReload()
            }
        })

    }
   
    useEffect(() => {
        reloadDataTable(dt, PATIENT_LIST)
    }, [dt, reload]);

    return (
        <>
            <Breadcrumbs pageName="Patient List" parent="Patient" />
            <div className="row">
                <div className="card px-3 py-4">
                    <DataTables dt={dt} dt_name="manage-patient" className="col-md-12" />
                </div>
            </div>
            <ConfirmationModal modal_id="patient_delete" action={() => deletePatient(deleteRecord, 'patient_delete')} />
        </>
    );
}
export default AllPatient


