import { Routes, Route } from "react-router-dom";
import { AuthLayout, UnAuthLayout } from './layouts/Layout';
import Dashboard from "../modules/Dashboard/Dashboard";
// import SignUp from "../modules/Auth/SignUp";
import Login from "../modules/Auth/Login";
import { AUTH_USER_INFO } from "./ApiRoutes";
import Context from "./Helpers/Context";
// import { store } from '../';
// import { Provider } from 'react-redux';
import { getData } from "./Helpers/Helper";
import { useEffect, useState } from "react";
import NotFound from "../modules/pages/NotFound";
import ManageDepartment from "../modules/Department/ManageDepartment";
import AddPatient from "../modules/Patient/AddPatient";
import AllPatient from "../modules/Patient/AllPatient";
import ProfileDoc from "../modules/Doctor/ProfileDoc";
import ForgotPassword from "../modules/Auth/ForgotPassword";
import ResetPassword from "../modules/Auth/ResetPassword";
import ChangePassword from "../modules/Auth/ChangePassword";
import UserProfile from "../modules/Auth/UserProfile";
import AddDepartment from "../modules/Department/AddDepartment";
import AddDoctor from "../modules/Doctor/AddDoctor";
import ManageDoctor from "../modules/Doctor/ManageDoctor";
import Roles from "../modules/User/Roles";
import Permission from "../modules/User/Permission";
import AssignPermission from "../modules/Doctor/AssignPermission";
import ManageRoom from "../modules/Room/ManageRoom";
import LabsImaging from "../modules/Lab/LabsImaging";
import LabsImagingList from "../modules/Lab/LabsImagingList";

export default function Routing() {

    const [context, setContext] = useState();

    let token = localStorage.getItem('hospital-token');

    useEffect(() => {
        if (token) {
            getData(AUTH_USER_INFO, true, (res) => {
                if (res.status) {
                    setContext(prevState => ({ ...prevState, auth: res.data }))
                }
            })
        }

    }, []);

    return (
        <>
            <Context.Provider value={[context, setContext]} >
                <Routes>
                    {/* <Route path="/test" caseSensitive={false} element={<Test />} /> */}
                    {/* <Route path="/" exact caseSensitive={false} element={<NormalLayout />}>
                        <Route path="/" caseSensitive={false} element={<Home />} />
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                    </Route> */}

                    <Route path="/" exact caseSensitive={false} element={<UnAuthLayout />}>
                        <Route path="/" caseSensitive={false} element={<Login />} />
                        <Route path="/forgot-password" caseSensitive={false} element={<ForgotPassword />} />
                        <Route path="/reset-password" caseSensitive={false} element={<ResetPassword/>} />
                    </Route>

                    <Route path="/" exact caseSensitive={false} element={<AuthLayout />}>
                        <Route path="/change-password" caseSensitive={false} element={<ChangePassword />} />
                        <Route path="/user-profile" caseSensitive={false} element={<UserProfile />} />
                        <Route path="/dashboard" caseSensitive={false} element={<Dashboard />} />
                       
                        <Route path="/add-department" caseSensitive={false} element={<AddDepartment />} />
                        <Route path="/edit-department" caseSensitive={false} element={<AddDepartment />} />
                        <Route path="/manage-department" caseSensitive={false} element={<ManageDepartment />} />
                        <Route path="/add-doctor" caseSensitive={false} element={<AddDoctor />} />
                        <Route path="/manage-doctor" caseSensitive={false} element={<ManageDoctor />} />
                        <Route path="/doctor-profile" caseSensitive={false} element={<ProfileDoc />} />
                        <Route path="/add-patient" caseSensitive={false} element={<AddPatient />} />
                        <Route path="/manage-patient" caseSensitive={false} element={<AllPatient />} />
                        <Route path="/roles" caseSensitive={false} element={<Roles />} />
                        <Route path="/permission" caseSensitive={false} element={<Permission />} />
                        <Route path="/assign-permission" caseSensitive={false} element={<AssignPermission />} />

                        <Route path="/labs-imaging" caseSensitive={false} element={<LabsImagingList />} />
                        <Route path="/add-labs-imaging" caseSensitive={false} element={<LabsImaging />} />
                        <Route path="/edit-labs-imaging" caseSensitive={false} element={<LabsImaging />} />

                        <Route path="/manage-room" caseSensitive={false} element={<ManageRoom />} />
                    </Route>

                    <Route path="*" caseSensitive={false} element={<NotFound />} />
                </Routes>
            </Context.Provider>
        </>
    )
}




