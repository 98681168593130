import { useEffect, useState } from "react"
import { ROLES_LIST } from "../../components/ApiRoutes"
import { Breadcrumbs } from "../../components/Helpers/Helper"
import { createRoot } from "react-dom/client";
import DataTables, { reloadDataTable } from "../../components/Helpers/DataTables"
import { ConfirmationModal, EditButton } from "../../components/FormHelper";
import AddRoles from "./AddRole";


const Roles = () => {


    const [reload, setReload] = useState(null);
    const [roleUpdate, setRoleUpdate] = useState(false)


    const [dt] = useState({
        dt_url: ROLES_LIST,
        dt_name: "role-list",
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "name", name: 'name', title: "Role Name" },
            { data: "action", name: 'action', title: "Action", searchable: false, sortable: false },
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="d-flex text-nowrap">
                                <EditButton modal_id="roleDetails" action={() => setRoleUpdate(records)} />
                            </div>
                        </>
                    );
                },
            }
        ],
        dt_filter: () => {
            createRoot(document.querySelector(`#adb_datatable_role-list_wrapper #dt-custom-filter`)).render(<>
                <button type="button" style={{height: "38px"}} className="btn btn-sm btn-primary mr-2"  title="Add Role" data-toggle="modal" data-target="#roleDetails">
                    Add Role
                </button>
                
            </>)
        }
    })


    useEffect(() => {
        reloadDataTable(dt, ROLES_LIST)
    }, [dt, reload]);

    return (
        <>
            <Breadcrumbs pageName="Roles" parent="User Management" />
            <div className="row">
                <div className="card px-3 py-4">
                    <div className="col">
                        <DataTables dt={dt} dt_name="role-list" className="col-md-12" />
                    </div>
                </div>
            </div>
            <AddRoles details={roleUpdate} reloadState={setRoleUpdate} load={setReload} />
            <ConfirmationModal modal_id="department_delete" />
        </>
    )
}
export default Roles
