
import { Link } from "react-router-dom"
import { InputField, SubmitButton } from "../../components/FormHelper";
import { initialFormState, postData, validateForm } from "../../components/Helpers/Helper";
import { useState } from "react";
import { FORGET_PASSWORD } from "../../components/ApiRoutes";

const ForgotPassword = () => {

    const [tinyloader, setTinyloader] = useState(false);
    const submitForm = (e) => {
        e.preventDefault();
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('forget-password-form'));
            postData(FORGET_PASSWORD, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('forget-password-form');
                }
            })
        }
    }

    return (
        <>
            <div id="wrapper" className="theme-cyan">
                <div className="vertical-align-wrap">
                    <div className="vertical-align-middle auth-main hospital">
                        <div className="auth-box">
                            <div className="top">
                                <img src="./logo.jpg" alt="Iconic" />
                                <span className="text-white h5 ml-2">Medical Dashboard</span>
                            </div>
                            <div className="card">
                                <div className="header">
                                    <p className="lead">Forgot Password</p>
                                </div>
                                <div className="body">
                                    <p>Please enter your email address below to receive instructions for resetting password.</p>
                                    <form className="form-auth-small needs-validation" id="forget-password-form" noValidate>
                                        <InputField type="email" id="email" name="email" required />
                                        <div className="d-flex justify-content-end">
                                            <SubmitButton load={tinyloader} type="submit" title="Submit" action={submitForm} />
                                        </div>
                                        <div className="bottom">
                                            <span className="helper-text">Know your password? <Link to="/">Login</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword